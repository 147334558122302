<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Voorwaarden</h1>
        <h2>Voorwaarden bij Hosting on Demand</h2>
        <p>Juridische voorwaarden zijn helaas onoverkomelijk, ook bij Hosting on Demand. Uiteraard kunnen we de belangrijkste voorwaarden wel in begrijpelijke taal presenteren, zodat goed duidelijk is hoe we er samen voor zorgen dat we webhosting, domeinregistratie en onze andere producten leuk houden voor iedereen.</p>
        <h3>Maximale uptime: jaar na jaar 99,99%</h3>
        <p>Een belangrijke voorwaarde voor een succesvolle website, server of applicatie is betrouwbaarheid, uit te drukken in de uptime. We slagen er inmiddels jaar na jaar in om 99,99% uptime te bieden, zodat uw website, server of applicatie optimaal bereikbaar is.<br><i>Let op: garanties bestaan niet en in het verleden behaalde resultaten zeggen niets over de toekomst. Gaat er toch een keer wat mis? Dan staat <router-link to="/klantenservice/">onze klantenservice</router-link> klaar.</i></p>
        <h3>We houden het gezellig</h3>
        <p>Wij gaan netjes om met onze klanten, dus datzelfde verwachten we andersom ook. Dreigende taal, racistische uitingen en ander onfatsoenlijk bedrag kunnen we niet tolereren. Niet naar ons toe en niet richting andere klanten. Bij aanhoudende problemen verwijderen we overlastplegers van onze servers en ontzeggen we die daar permanent de toegang tot.</p>
        <h3>Verantwoord met uw gegevens</h3>
        <p>We gaan verantwoord om met uw gegevens, die we nodig hebben voor een goede dienstverlening. Dat betekent dat we alleen de gegevens verzamelen die we nodig hebben om u optimaal van dienst te kunnen zijn en dat we die veilig opslaan.</p>
        <p>We verkopen of verhuren nooit persoonsgegevens voor marketingdoeleinden. We sturen slechts poststukken en e-mails wanneer dat noodzakelijk is voor onze dienstverlening of als we in-house of in samenwerking met onze eigen toeleveranciers promoties willen uitbrengen. Geen zorgen over privacy, wij gaan verantwoord met uw gegevens om.</p>
        <h3>We blokkeren bij overlast</h3>
        <p>Websites op onze servers die overlast bezorgen blokkeren we tijdelijk. We geven waarschuwingen aan torrent-sites en wanneer er sprake is van porno, warez of andere criminele activiteiten. Tijdens de waarschuwing is de website niet meer bereikbaar, om de problemen op te lossen. Indien dat niet gebeurt zullen we de website volledig verwijderen van onze servers, zodat anderen daar geen last van hebben.</p>
        <h3>Automatische incasso met herstelkans</h3>
        <p>We maken bij Hosting on Demand gebruik van automatische incasso’s om de kosten voor domeinen, webhosting en onze andere diensten te incasseren. Dat gebeurt automatisch om u zoveel mogelijk te ontlasten, waardoor u daar weinig tot geen omkijken naar heeft.</p>
        <p>Zodra een automatische incasso, om welke reden dan ook, mislukt brengen we u daarvan op de hoogte en bieden we u de kans dit alsnog (handmatig) te herstellen. Blijft de betaling uit? Dan sluiten we een website op onze servers af, net zo lang totdat we de betaling wel ontvangen. Op die manier houden we het leuk voor u en voor ons.</p>
        <p>De volledige Algemene Voorwaarden van Hosting on Demand inzien? Neem contact met ons op of download ze online, we vertellen u er graag meer over.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'Requirements',
  components: {
    Breadcrumbs,
  },
}
</script>